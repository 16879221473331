<template>
    <div class="growth-program-old-style">
        <back-to-top-custom />

        <div class="container-fluid mt-4">
            <div class="row justify-content-center">
                <div class="col-lg-8 justify-content-center">
                  <div v-if="unfollowedUsers" class="d-flex justify-content-end">
                    <base-button type="primary"
                                 class="align-right"
                                 :disabled="!unfollowedUsers.length || isLoading"
                                 @click="followCurrentGrowthProgramUsers">
                      {{displayFollowStatus}}
                    </base-button>
                  </div>
                  <div v-if="Object.keys(retweetsPerDay).length === 0">
                    No tweets to validate.
                  </div>
                  <div v-bind:key="day" v-for="(retweets, day) in retweetsPerDay">
                    <h3>
                      Day {{day}} -
                      Total tweets: {{retweets.length}}
                    </h3>
                    <div v-bind:key="retweet.id" v-for="retweet in retweets">
                        <card :no-body="true">
                            <div class="card-body container-fluid">
                                <div class="row">
                                    <div class="col-12">
                                        <p class="lead mt-0 card-text white-space-pre">{{ retweet.originalTweetInfo.text }}</p>
                                    </div>
                                </div>
                            </div>

                            <div slot="footer" class="row">
                              <div class="col-6">
                                Will be retweeted at {{retweet.formatedTime}}<br/>
                                For user <b><a target="_blank" :href="'https://twitter.com/' + retweet.originalTweetInfo.username">
                                  {{retweet.originalTweetInfo.userDisplayName}}
                              </a></b>
                              </div>
                                <div class="text-right col-6">
                                    <p class="mb-0">
                                      <base-checkbox v-model="retweet.growthProgramIsValidated">Validate</base-checkbox>
                                    </p>
                                </div>
                            </div>
                        </card>
                    </div>
                    <div class="row px-3">
                      <div class="col-9"></div>
                      <base-button type="success" class="col-3 align-content-end" @click="confirmRetweetsValidation(day)">
                        Confirm day {{day}}
                      </base-button>
                    </div>
                  </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import firebase from 'firebase/compat/app';
  import 'firebase/compat/firestore'
  import moment from 'moment';
  import 'moment-timezone';
  import lodash from 'lodash';
  import SwalModalMixin from '@/views/Mixins/SwalModalMixin.vue';
  import controller from '@/controller';
  import dao from '@/dao';
  import { mapGetters } from 'vuex';
  import {Retweet} from '@/models/Retweet';
  import BackToTopCustom from "@/views/Widgets/BackToTopCustom";
  import '@/assets/sass/growthprogram.old.scss';
  import GrowthProgramMixin from '@/views/Mixins/GrowthProgramMixin';
  import { store } from '@/store';
  const fb = require('@/firebase');

  export default {
    name: 'InfluencerPage',
    components: {
      BackToTopCustom,
    },
    data() {
      return {
        followedUsers: null,
        growthProgramUsersTweetIds: null,
        isLoading: null,
        retweetsPerDay: [],
        selectedGrowthProgram: null,
        unfollowedUsers: null,
      };
    },
    computed: {
      displayFollowStatus() {
        if (this.isLoading) {
          return 'In Progres...';
        }

        return this.unfollowedUsers.length ? 'Follow All Users' : 'You are following all users';
      },
      ...mapGetters({ currentUser: 'getCurrentUser', userProfile: 'getUserProfile' }),
    },
    methods: {
      assignCurrentGrowthProgramUsersTweetIds() {
        const usersTweetIdsPromises = this.selectedGrowthProgram.customers.map((customer) =>
          dao.userProfile.getUser(customer.id).then((doc) => doc.data().twitterId)
        );

        Promise.all(usersTweetIdsPromises).then(res => this.growthProgramUsersTweetIds = res);
      },
      assignFollowedUsers() {
        controller
          .getAccountsFollowedByUserFromList(
            this.growthProgramUsersTweetIds,
            this.currentUser,
            this.userProfile.uid
          )
          .then((res) => (this.followedUsers = res));
      },
      assignUnfollowedUsers() {
        this.unfollowedUsers = _.difference(this.growthProgramUsersTweetIds, this.followedUsers);
      },
      confirmRetweetsValidation(day) {
        this.swalModal({
          title: 'Validate boosts',
          text: 'Are you sure you want to validate these boosts?',
          type: 'info',
          showCancelButton: true,
          confirmButtonText: 'Yes',
        })
        .then(result => {
          if (!result.value) return;

          const retweets = this.retweetsPerDay[day];

          const validatedRetweets = retweets.filter(retweet => retweet.growthProgramIsValidated);
          validatedRetweets.map(retweet => {
            fb.threadsCollection.doc(retweet.id).update({
              growthProgramIsValidated: true,
              time: retweet.time,
              timeForRetweet: null,
              updated_at: firebase.firestore.FieldValue.serverTimestamp(),
            });
          });

          const rejectedRetweets = retweets.filter(retweet => !retweet.growthProgramIsValidated);
          rejectedRetweets.map(retweet => {
            fb.threadsCollection.doc(retweet.id).delete();
          });
        });
      },
      followCurrentGrowthProgramUsers() {
        this.isLoading = true;

        controller.twitter
          .followUsers(this.unfollowedUsers, this.currentUser, this.userProfile.uid)
          .then(() => {
            this.isLoading = false;
            this.unfollowedUsers = [];
          });
      },
      updateInfluencerRetweets() {
        const userRef = fb.usersCollection.doc(this.userProfile.uid);

        const that = this;

        const unsubscribe = fb.threadsCollection
          .where('deleted', '==', false)
          .where('user', '==', userRef)
          .where('growthProgramIsValidated', '==', false)
          .where('type', '==', 'retweet')
          .where('source', '==', 'growth-program')
          .onSnapshot((doc) => {
            const retweets = doc.docs.filter(doc => doc.data().timeForRetweet).map(d => {
              const retweet = Retweet.newRetweet(
                null,
                d.data().timeForRetweet,
                d.data().user,
                d.data().originalTweetInfo,
              );
              retweet.id = d.id;
              retweet.growthProgramIsValidated = true;
              retweet.day = moment.tz(d.data().timeForRetweet.toDate(), 'GMT').date();
              retweet.formatedTime = moment(retweet.time.toDate()).format('h:mm A');

              return retweet;
            });

            that.retweetsPerDay = lodash.groupBy(lodash.sortBy(retweets, 'time'), 'day');
          });

          store.dispatch('addFirestoreListener', unsubscribe);
      },
    },
    mounted () {
      this.getCurrentGrowthProgram();
      this.updateInfluencerRetweets();
    },
    mixins: [GrowthProgramMixin, SwalModalMixin],
    watch: {
      selectedGrowthProgram() {
        this.assignCurrentGrowthProgramUsersTweetIds();
      },
      growthProgramUsersTweetIds() {
        this.assignFollowedUsers();
      },
      followedUsers() {
        this.assignUnfollowedUsers();
      }
    }
  };
</script>

<style scoped>
    .float-btn {
      position: fixed;
      bottom: 2rem;
      right: 2rem;
    }
</style>
